import { Table, Typography, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { InitialColumns } from './initialColumns';
import { CashDeliveryContext } from '../context/cashDeliveryContext';

import Filters from './Filters';
import MultiplePay from './MultiplePay';

const { Title } = Typography;

const TableDispersions = () => {
    const INITIAL_COLUMNS: any = InitialColumns();

    const {
        loading,
        dispersions,
        dispersionsToPay,
        total,
        paginator,
        onPageChange,
        fetchDispersions,
        searchParams,
        selectedRowKeys,
        setSelectedRowKeys,
        handleParams,
        handlePayMultiple,
        setDispersionsToPay,
        payMultipleDispersions,
        handleDownload,
    } = useContext(CashDeliveryContext);

    const { limit, page } = paginator || { limit: 0, skip: 0 };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        fetchDispersions();
    }, [paginator, searchParams]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            {
                dispersionsToPay.length > 0 && (
                    <MultiplePay
                        open={dispersionsToPay.length > 0}
                        onClose={() => setDispersionsToPay([])}
                        dispersionsToPay={dispersionsToPay}
                        payMultipleDispersions={payMultipleDispersions}
                    />
                )
            }
            <Filters
                searchParams={searchParams}
                hasSelected={selectedRowKeys.length > 1}
                handlePayMultiple={handlePayMultiple}
                handleDownload={handleDownload}
                handleParams={handleParams}
            />
            <Table
                title={
                    () => (
                        <Title level={4}>
                            Dispersions table
                        </Title>
                    )
                }
                rowSelection={rowSelection}
                loading={loading}
                columns={INITIAL_COLUMNS}
                dataSource={dispersions}
                pagination={{
                    total,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1500 }}
                rowKey="_id"
            />
        </Space>
    );
};

export default TableDispersions;
