import {
    Button,
    Col,
    Modal,
    notification,
    Row,
    Space,
    Switch,
    Typography,
} from 'antd';
import { useContext, useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { copyText } from '../../../../utils/general';
import CardDataBase from '../../../CardDataBase';
import { editWorkspace } from '../../../../api/workspaces';
import ChangeConfig from './ChangeConfig';
import { WorkspaceDetailsContext } from '../../../../screens/WorkspaceDetails/context/WorkspaceDetailsContext';

const { Text } = Typography;

interface Props {
    workspaceId: string;
    masterKey: string;
    requiresProofOfDelivery: boolean;
}

const SettingsDetail = ({
    workspaceId,
    masterKey,
    requiresProofOfDelivery,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [proofActive, setProofActive] = useState(requiresProofOfDelivery);

    const { workspace, setWorkspace } = useContext(WorkspaceDetailsContext);

    const handleProofChange = async (value: boolean) => {
        setLoading(true);
        editWorkspace(workspaceId, { requiresProofOfDelivery: value })
            .then(() => setProofActive(value))
            .catch((error) => notification.error({
                message: 'Error',
                description: error || error.message || 'An error occurred',
            }))
            .finally(() => setLoading(false));
    };

    const CARD_CONTENT = [
        {
            title: 'Master Key',
            content: (
                <Space>
                    {masterKey}
                    <Button
                        type="link"
                        size="small"
                        icon={(
                            <AiOutlineCopy
                                style={{ verticalAlign: 'middle' }}
                                size={18}
                            />
                        )}
                        onClick={() => copyText({ value: masterKey, messageSuccess: 'Master key copied!' })}
                        style={{ padding: 0 }}
                    />
                </Space>
            ),
        },
        {
            title: 'Proof of Delivery',
            content: (
                <Switch
                    checked={proofActive}
                    onChange={handleProofChange}
                    loading={loading}
                />
            ),
        },
        {
            title: 'Driver phone certification',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.isPhoneCertificationEnabled}
                    configName="Driver Phone Certification"
                    varName="isPhoneCertificationEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
        {
            title: 'Express Delivery',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.isExpressDeliveryEnabled}
                    configName="Express Delivery"
                    varName="isExpressDeliveryEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
        {
            title: 'SMR Brand Kit',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.isBrandKitEnabled}
                    configName="SMR Brand Kit"
                    varName="isBrandKitEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
        {
            title: 'Whatsapp certification',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.isWhatsappCertificationEnabled}
                    configName="Whatsapp certification"
                    varName="isWhatsappCertificationEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
        {
            title: 'Account and Billing',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.isBillingEnabled}
                    configName="Account and Billing"
                    varName="isBillingEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
        {
            title: 'Link to Pay',
            content: (
                <ChangeConfig
                    checked={workspace?.workspaceSettings?.linkToPayEnabled}
                    configName="Link to Pay"
                    varName="linkToPayEnabled"
                    workspace={workspace}
                    setWorkspace={setWorkspace}
                />
            ),
        },
    ];

    return (
        <CardDataBase title="Settings" style={{ minHeight: 345 }}>
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default SettingsDetail;
