import {
    Form,
    Modal,
    Input,
    Typography,
    notification,
    Select,
} from 'antd';
import { useContext, useState } from 'react';
import { addInvoiceTransaction } from '../../../api/dispersions';
import { DispersionContext } from '../../DispersionDetail/context/dispersionContext';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

interface IProps {
    dispersion: any
    visible: boolean;
    allTransactions: any;
    onCancel: (transaction: any) => void;
}

export const AddTransactionModal = ({
    dispersion,
    visible,
    allTransactions,
    onCancel,
}: IProps) => {
    const { fetchDispersion } = useContext(DispersionContext);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        addInvoiceTransaction(dispersion._id, values)
            .then(({ data }) => {
                notification.success({
                    message: 'Transaction added successfully',
                });
                fetchDispersion();
                onCancel(data.data);
            })
            .catch((error) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: 'Error adding transaction',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                form.resetFields();
            });
    };

    // Only for change textArea to select
    // const options = [
    //     { value: 'Ajuste', label: 'Razón 1' },
    //     { value: 'Pago', label: 'Razón 2' },
    //     { value: 'Reembolso', label: 'Razón 3' },
    // ];

    return (
        <Modal
            visible={visible}
            title="Add new transaction"
            onCancel={() => onCancel(null)}
            onOk={() => form.submit()}
            okText="Add"
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a type!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select a type"
                    >
                        <Option value="SCOPE">Ajuste</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="bookingNumericId"
                    label="Booking (optional)"
                    help="You can set booking if you want to pay for a specific booking"
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder="Select a person"
                        filterOption={(input, option) => (option?.value ?? '').toString().toLowerCase().includes(input.toString())}
                    >
                        {
                            allTransactions.current.map((transaction: any) => (
                                <Option value={transaction.bookingNumericId}>
                                    {transaction.bookingNumericId}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[
                        {
                            required: true,
                            message: 'Please input amount!',
                        },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="comment"
                    label="Comment"
                >
                    {/* <Select
                        options={options}
                        placeholder="Select a reason"
                    /> */}
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
