import { notification } from 'antd';
import { createContext, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDispersion, getTransactions } from '../../../api/dispersions';
import { IDispersion } from '../../../interfaces/dispersion.interface';

interface IDispersionContext {
    loading: boolean,
    loadingTransactions: boolean,
    dispersion: IDispersion,
    totalTransactions: number,
    paginator: {
        limit: number,
        page: number,
    },
    onPageChange: (page: number, pageSize: number) => void,
    onTableChange: (pagination: any, filters: any, sorter: any) => void,
    fetchDispersion: () => void,
}

const initialContext = {
    loading: false,
    loadingTransactions: false,
    dispersion: {} as IDispersion,
    totalTransactions: 0,
    paginator: {
        limit: 5,
        page: 1,
    },
    onPageChange: () => { },
    onTableChange: () => { },
    fetchDispersion: () => { },
};

export const DispersionContext = createContext<IDispersionContext>(initialContext);

export const DispersionProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingTransactions, setLoadingTransactions] = useState<boolean>(initialContext.loadingTransactions);
    const [dispersion, setDispersion] = useState<IDispersion>(initialContext.dispersion);
    const [totalTransactions, setTotalTransactions] = useState<number>(initialContext.totalTransactions);
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const { id } = useParams();

    const dispersionID = id;

    const params: any = {
        ...paginator,
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        const { current, pageSize } = pagination;
        if (current !== paginator.page || pageSize !== paginator.limit) {
            setPaginator({
                limit: pageSize,
                page: current,
            });
        }
        let total = dispersion.dispersionTransactions?.length || 0;
        if (filters.isCashCollect?.length === 1) {
            const cashCollect = dispersion.dispersionTransactions?.filter((transaction) => transaction.isCashCollect);
            const totalCollect = cashCollect?.length || 0;
            const totalInStore = total - totalCollect;
            if (filters.isCashCollect.includes(true)) total = totalCollect;
            else total = totalInStore;
        }
        setTotalTransactions(total);
    };

    const fetchDispersion = () => {
        setLoading(true);
        getDispersion(dispersionID as string)
            .then((response: any) => {
                const { data: dispersionData } = response.data;
                if (dispersionData?._id) {
                    setLoadingTransactions(true);
                    getTransactions(dispersionID as string)
                        .then((response: any) => {
                            const { data: { data } } = response;
                            setDispersion({
                                ...dispersionData,
                                dispersionTransactions: data.data,
                            });
                            setTotalTransactions(data.data.total);
                        })
                        .catch((error: any) => {
                            console.log({ error });
                            const description = error.response.data.description;
                            notification.error({
                                message: 'Error',
                                description,
                            });
                        })
                        .finally(() => setLoadingTransactions(false));
                }
            })
            .catch((error: any) => {
                console.log({ error });
                const description = error.response.data.description;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <DispersionContext.Provider
            value={{
                loading,
                loadingTransactions,
                dispersion,
                totalTransactions,
                paginator,
                onPageChange,
                onTableChange,
                fetchDispersion,
            }}
        >
            {children}
        </DispersionContext.Provider>
    );
};
