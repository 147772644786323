import {
    Card, Space, Spin, Timeline, Typography, message,
} from 'antd';
import moment from 'moment';
import { BsBell } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { getBookingEvents } from '../../api/booking';
import { BookingEvent } from '../../types/booking';
import { getEventsFromTracking } from '../../api/inspector';
import { IInspectorEvent } from '../../interfaces/inspector.interface';
import { processEvents } from '../../utils/event';
import BookingEventCard from './BookingEvent';
import InspectorEventCard from './InspectorEvent';
import { getPreBookingEvents } from '../../api/link-to-deliver';

interface EventsProps {
  bookingId: string;
  preBookingId?: string;
}

const { Text } = Typography;

export const Events = ({ bookingId, preBookingId }: EventsProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [allEvents, setAllEvents] = useState<any[]>([]);
    const [inspectorEvents, setInspectorEvents] = useState<IInspectorEvent[]>([]);
    const [bookingEvents, setBookingEvents] = useState<BookingEvent[]>([]);
    const [preBookingEvents, setPreBookingEvents] = useState<any[]>([]);

    const fetchBookingEvents = () => getBookingEvents(bookingId)
        .then(({ data }) => {
            const eventsWithType = data.data.map((event: any) => ({
                ...event,
                eventType: 'BOOKING_EVENT',
            }));
            setBookingEvents(eventsWithType);
        })
        .catch((error) => {
            const description = error.response?.data?.message || error.message;
            message.error(`Failed to fetch booking events: ${description}`);
        });

    const fetchInspectorEvents = () => getEventsFromTracking(bookingId)
        .then(({ data: { data } }) => {
            let events = data as IInspectorEvent[];
            events = processEvents(events);
            setInspectorEvents(events);
        })
        .catch((error) => {
            const description = error.response?.data?.message || error.message;
            message.error(`Failed to fetch inspector events: ${description}`);
        });

    const fetchPreBookingEvents = () => {
        if (!preBookingId) return;
        getPreBookingEvents(preBookingId)
            .then(({ data: { data } }) => {
                setPreBookingEvents(data);
            })
            .catch((error) => {
                const description = error.response?.data?.message || error.message;
                message.error(`Failed to fetch pre-booking events: ${description}`);
            });
    };

    const fetchData = async () => {
        await Promise.all([fetchBookingEvents(), fetchInspectorEvents(), fetchPreBookingEvents()])
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (inspectorEvents.length === 0 && bookingEvents.length === 0 && preBookingEvents.length === 0) return;

        console.log('inspectorEvents', inspectorEvents);
        console.log('bookingEvents', bookingEvents);
        console.log('preBookingEvents', preBookingEvents);

        setAllEvents([]);
        const combinedList = [...inspectorEvents, ...bookingEvents, ...preBookingEvents];

        const listSorted = combinedList.sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            return dateA - dateB;
        });
        setAllEvents(listSorted);
    }, [inspectorEvents, bookingEvents, preBookingEvents]);

    useEffect(() => {
        if (!bookingId) return;
        fetchData();
    }, [bookingId]);

    return (
        <Card
            title={(
                <Space size={8} align="center">
                    <BsBell style={{ verticalAlign: 'middle' }} />
                    <Text>Events</Text>
                </Space>
            )}
        >
            {
                loading ? (
                    <Space size={8}>
                        <Spin size="small" />
                        <Text>
                            Loading events...
                        </Text>
                    </Space>
                ) : (
                    <Timeline mode="left">
                        {
                            allEvents.map((item: any) => {
                                const time = moment(item.createdAt).local().format('HH:mm:ss:SSS');
                                const date = moment(item.createdAt).local().format('ddd DD MMM');

                                return (
                                    <Timeline.Item
                                        key={item._id}
                                        label={(
                                            <Space size={4}>
                                                <Text>
                                                    {date}
                                                </Text>
                                                <Text type="secondary">
                                                    at
                                                </Text>
                                                <Text>
                                                    {time}
                                                </Text>
                                            </Space>
                                        )}
                                    >
                                        {item.eventType === 'INSPECTOR_EVENT' ? (
                                            <InspectorEventCard eventData={item} />
                                        ) : (
                                            <BookingEventCard eventData={item} />
                                        )}
                                    </Timeline.Item>
                                );
                            })
                        }
                    </Timeline>
                )
            }
        </Card>
    );
};
