/* eslint-disable max-len */
import xlsx from 'json-as-xlsx';
import moment from 'moment';
import _ from 'lodash';
import { parse } from 'json2csv';
import { FileType } from '../types/reports';

export const HEADERS: any = {
    workspacesReport: {
        userID: { label: 'User ID', value: 'userID' },
        userName: { label: 'User Name', value: 'userName' },
        userLastName: { label: 'User Last Name', value: 'userLastName' },
        userEmail: { label: 'User Email', value: 'userEmail' },
        workspaceName: { label: 'Workspace Name', value: 'workspaceName' },
        workspaceID: { label: 'Workspace Id', value: 'workspaceID' },
        workspacePlan: { label: 'Workspace Plan', value: 'workspacePlan' },
        country: { label: 'Workspace Country', value: 'country' },
        currency: { label: 'Workspace Currency', value: 'currency' },
        isDeleted: { label: 'Workspace Deleted', value: 'isDeleted' },
        deletedAtDate: { label: 'Workspace Deleted Date', value: (row: any) => (row.deletedAt ? moment(row.deletedAt).format('yyyy/MM/DD') : '') },
        deletedAtHour: { label: 'Workspace Deleted Hour', value: (row: any) => (row.deletedAt ? moment(row.deletedAt).format('HH:mm:ss') : '') },
        storeID: { label: 'Store Id', value: 'storeID' },
        storeName: { label: 'Store Name', value: 'storeName' },
        storePhone: { label: 'Store Phone', value: 'storePhone' },
        storeAddress: { label: 'Store Address', value: 'storeAddress' },
        storeCity: { label: 'Store City', value: 'storeCity' },
        storeCountry: { label: 'Store Country', value: 'storeCountry' },
        storeCreationDate: { label: 'Store Creation Date', value: (row: any) => (row.storeCreationDate ? moment(row.storeCreationDate).format('yyyy/MM/DD') : '') },
        storeCreationHour: { label: 'Store Creation Hour', value: (row: any) => (row.storeCreationDate ? moment(row.storeCreationDate).format('HH:mm:ss') : '') },
        storeReference: { label: 'Store Reference', value: 'storeReference' },
        storeRegion: { label: 'Store Region', value: 'storeRegion' },
        storeSuperRegion: { label: 'Store Super Region', value: 'storeSuperRegion' },
        storeUniqueID: { label: 'Store Unique Id', value: 'storeUniqueID' },
        storeCurrency: { label: 'Store Currency', value: 'storeCurrency' },
        billingEmail: { label: 'Billing Email', value: 'billingEmail' },
        billingMethod: { label: 'Billing Method', value: 'billingMethod' },
        billingUser: { label: 'Billing User', value: 'billingUser' },
        freeTrialExpirationTime: { label: 'Free Trial Expiration', value: (row: any) => (row.freeTrialExpirationTime ? moment(row.freeTrialExpirationTime).format('yyyy/MM/DD') : '') },
        cardReference: { label: 'Card Reference', value: 'cardReference' },
        hasCreatedFirstBooking: { label: 'Has Created First Booking', value: 'hasCreatedFirstBooking' },
        hasEmail: { label: 'Has Notification Email', value: 'hasEmail' },
        hasSMS: { label: 'Has Notification SMS', value: 'hasSMS' },
        hasWhatsapp: { label: 'Has Notification Whatsapp', value: 'hasWhatsapp' },
        nextBillingDate: { label: 'Next Billing Date', value: (row: any) => (row.nextBillingDate ? moment(row.nextBillingDate).format('yyyy/MM/DD') : '') },
        lastStepCompleted: { label: 'Last Step Completed', value: 'lastStepCompleted' },
        userCreationDate: { label: 'User Creation Date', value: (row: any) => (row.userCreationDate ? moment(row.userCreationDate).format('yyyy/MM/DD') : '') },
        userCreationHour: { label: 'User Creation Hour', value: (row: any) => (row.userCreationDate ? moment(row.userCreationDate).format('HH:mm:ss') : '') },
        workspaceCreationDate: { label: 'Workspace Creation Date', value: (row: any) => (row.workspaceCreationDate ? moment(row.workspaceCreationDate).format('yyyy/MM/DD') : '') },
        workspaceCreationHour: { label: 'Workspace Creation Hour', value: (row: any) => (row.workspaceCreationDate ? moment(row.workspaceCreationDate).format('HH:mm:ss') : '') },
        RUC: { label: 'RUC', value: 'RUC' },
        EstimatedTimeToPointA: { label: 'Accepted Optimal Time (s)', value: 'estimatedTimeToPointA' },
        EstimatedTimeToPointB: { label: 'Way To Deliver Optimal Time (s)', value: 'estimatedTimeToPointB' },
        Tracking: { label: 'Tracking', value: 'tracking' },
        TrackingPercentCost: { label: 'Tracking Percent Cost', value: 'trackingPercentCost' },
        TrackingAmountCost: { label: 'Tracking Amount Cost', value: 'trackingAmountCost' },
        CashDelivery: { label: 'Cash Delivery', value: 'cashDelivery' },
        CashDeliveryPercentCost: { label: 'Cash Delivery Percent Cost', value: 'cashDeliveryPercentCost' },
        CashDeliveryAmountCost: { label: 'Cash Delivery Amount Cost', value: 'cashDeliveryAmountCost' },
        CardDelivery: { label: 'Card Delivery', value: 'cardDelivery' },
        CardDeliveryPercentCost: { label: 'Card Delivery Percent Cost', value: 'cardDeliveryPercentCost' },
        CardDeliveryAmountCost: { label: 'Card Delivery Amount Cost', value: 'cardDeliveryAmountCost' },
        Orchestrator: { label: 'Orchestrator', value: 'orchestrator' },
        OrchestratorPercentCost: { label: 'Orchestrator Percent Cost', value: 'orchestratorPercentCost' },
        OrchestratorAmountCost: { label: 'Orchestrator Amount Cost', value: 'orchestratorAmountCost' },
        MyFleet: { label: 'MyFleet', value: 'myFleet' },
        MyFleetPercentCost: { label: 'MyFleetPercentCost', value: 'myFleetPercentCost' },
        MyFleetAmountCost: { label: 'MyFleetAmountCost', value: 'myFleetAmountCost' },
    },
    totalOrderRequest: {
        rideId: { label: 'Ride Id', value: 'rideId' },
        providerName: { label: 'Provider Name', value: 'providerName' },
        status: { label: 'Status', value: 'status' },
        externalId: { label: 'External Id', value: 'externalId' },
        externalSupportId: { label: 'External Support Id', value: 'externalSupportId' },
        bookingNumericId: { label: 'Booking Numeric Id', value: 'bookingNumericId' },
        externalStatus: { label: 'External Status', value: 'externalStatus' },
        providerFee: { label: 'Provider Fee', value: (row: any) => row.providerFee || 0 },
        isActive: { label: 'Is active', value: 'isActive' },
        createdAt: { label: 'Created At', value: (row: any) => moment(row.createdAt).format('DD/MM/Y h:mm:ss a') },
        updatedAt: { label: 'Updated At', value: (row: any) => moment(row.updatedAt).format('DD/MM/Y h:mm:ss a') },
        tip: { label: 'Tip', value: (row: any) => row.tip || 0 },
        errorMessage: { label: 'Error Message', value: 'errorMessage' },
    },
    totalOrderRequestNew: {
        BookingId: { label: 'Booking Id', value: 'bookingId' },
        OrderId: { label: 'Order Id', value: 'orderId' },
        CreatedAtDate: { label: 'Created At Date', value: 'createdAtDate' },
        CreatedAtTime: { label: 'Created At Time', value: 'createdAtTime' },
        ProviderName: { label: 'Provider Name', value: 'providerName' },
        PickerStatus: { label: 'Picker Status', value: 'pickerStatus' },
        ErrorMessage: { label: 'Error Message', value: 'errorMessage' },
        UpdatedAtDate: { label: 'Updated At Date', value: 'updatedAtDate' },
        UpdatedAtTime: { label: 'Updated At Time', value: 'updatedAtTime' },
        ExternalId: { label: 'External Id', value: 'externalId' },
        ExternalSupportId: { label: 'External Support Id', value: 'externalSupportId' },
        ExternalStatus: { label: 'External Status', value: 'externalStatus' },
        ProviderFee: { label: 'Provider Fee', value: 'providerFee' },
        IsActive: { label: 'Is Active', value: 'isActive' },
        DriverName: { label: 'Driver Name', value: 'driverName' },
        DriverEmail: { label: 'Driver Email', value: 'driverEmail' },
        DriverPhone: { label: 'Driver Phone', value: 'driverPhone' },
        TrackingLink: { label: 'Tracking Link', value: 'trackingLink' },
        TimestampAccepted: { label: 'Timestamp Accepted', value: 'timestampAccepted' },
        TimestampArrivedAtPickup: { label: 'Timestamp Arrived At Pickup', value: 'timestampArrivedAtPickup' },
        TimestampWayToDeliver: { label: 'Timestamp Way To Deliver', value: 'timestampWayToDeliver' },
        TimestampArrivedAtDelivery: { label: 'Timestamp Arrived At Delivery', value: 'timestampArrivedAtDelivery' },
        TimestampCompleted: { label: 'Timestamp Completed', value: 'timestampCompleted' },
        TimestampCanceled: { label: 'Timestamp Canceled', value: 'timestampCanceled' },
        CancelReason: { label: 'Cancel Reason', value: 'cancelReason' },
        Tip: { label: 'Tip', value: 'tip' },
        CancelAdminReason: { label: 'Cancel Admin Reason', value: 'cancelAdminReason' },
        PreviousBookingStatus: { label: 'Previous Booking Status', value: 'previousBookingStatus' },
        PreviousOrderStatus: { label: 'Previous Order Status', value: 'previousOrderStatus' },
    },
    newServiceRequestReport: {
        BookingID: { label: 'BookingID', value: 'bookingNumericId' },
        TypeofService: { label: 'Type of Service', value: 'typeOfService' },
        BookingStatus: { label: 'Booking Status', value: 'bookingStatus' },
        PaymentMethod: { label: 'Payment Method', value: 'paymentMethod' },
        BillingMethod: { label: 'Billing Method', value: 'billingMethod' },
        BookingStartDate: { label: 'Booking Start Date', value: 'startDate' },
        BookingStartTime: { label: 'Booking Start Time(HH:mm:ss)', value: 'startTime' },
        BookingAcceptedDate: { label: 'Booking Accepted Date', value: 'acceptedDate' },
        BookingAcceptedTime: { label: 'Booking Accepted Time (HH:mm:ss)', value: 'acceptedTime' },
        BookingCancelledDate: { label: 'Booking Cancelled Date', value: 'cancelledDate' },
        BookingCancelledTime: { label: 'Booking Cancelled Time (HH:mm:ss)', value: 'cancelledTime' },
        BookingCancelledBy: { label: 'Booking Cancelled By', value: 'cancelledBy' },
        CancelReason: { label: 'Cancel Reason', value: 'cancelReason' },
        BookingCompletedDate: { label: 'Booking Completed Date', value: 'completedDate' },
        BookingCompletedTime: { label: 'Booking Completed Time (HH:mm:ss)', value: 'completedTime' },
        TimetoCancellation: { label: 'Time to Cancellation', value: 'timeToCancellation' },
        TimetoPointA: { label: 'Time to Point A (s)', value: 'timeToPointA' },
        TimeAtRestaurant: { label: 'Time At Restaurant (s)', value: 'timeToWTD' },
        TimetoCompleted: { label: 'Time to Completed (s)', value: 'timeToCompleted' },
        PickupLat: { label: 'Pickup Lat', value: 'pointALat' },
        PickupLng: { label: 'Pickup Lng', value: 'pointALng' },
        PickupAddress: { label: 'Pickup Address', value: 'pickupAddress' },
        PickupReference: { label: 'Pickup Reference', value: 'pickupReference' },
        PickupState: { label: 'Pickup State', value: 'pickupState' },
        PickupCity: { label: 'Pickup City', value: 'pickupCity' },
        PickupZipCode: { label: 'Pickup Zip Code', value: 'pickupZipCode' },
        DeliveryLat: { label: 'Delivery Lat', value: 'dropOffLat' },
        DeliveryLng: { label: 'Delivery Lng', value: 'dropOffLng' },
        DeliveryAddress: { label: 'Delivery Address', value: 'dropOffAdress' },
        DeliveryReference: { label: 'Delivery Reference', value: 'deliveryReference' },
        DeliveryState: { label: 'Delivery State', value: 'dropOffState' },
        DeliveryCity: { label: 'Delivery City', value: 'dropOffCity' },
        DeliveryZipCode: { label: 'Delivery Zip Code', value: 'dropOffZipCode' },
        RealDistance: { label: 'Real Distance', value: 'realDistance' },
        TravellingSpeed: { label: 'Travelling Speed', value: 'speed' },
        CustomerName: { label: 'Customer Name', value: 'customerName' },
        CustomerPhone: { label: 'Customer Phone', value: 'customerMobile' },
        CustomerEmail: { label: 'Customer Email', value: 'customerEmail' },
        CustomerSegment: { label: 'Customer Segment', value: 'customerSegment' },
        DeviceID: { label: 'Device ID', value: 'customerDeviceID' },
        CustomerRealLat: { label: 'Customer Real Lat', value: 'realLat' },
        CustomerRealLng: { label: 'Customer Real Lng', value: 'realLng' },
        CustomerDevice: { label: 'Customer Device', value: 'customerDevice' },
        IsFirstBooking: { label: 'Is first booking', value: 'isFirstBooking' },
        DriverName: { label: 'Driver Name', value: 'driverName' },
        DeliveryProvider: { label: 'Delivery Provider', value: 'deliveryProvider' },
        DriverTag: { label: 'Driver Tag', value: 'driverTag' },
        DriverPhone: { label: 'Driver Phone', value: 'driverMobile' },
        DriverEmail: { label: 'Driver Email', value: 'driverEmail' },
        DriverAccountingType: { label: 'Driver Accounting Type', value: 'driverAccountingType' },
        DriverPickerPoints: { label: 'Driver Picker Points', value: 'driverPickerPoints' },
        BusinessName: { label: 'Business Name', value: 'businessName' },
        BusinessTrafficLight: { label: 'Business Traffic Light', value: 'businessTrafficLight' },
        BusinessPhone: { label: 'Business Phone', value: 'businessMobile' },
        BusinessMail: { label: 'Business Mail', value: 'businessEmail' },
        BusinessUniqueID: { label: 'Business Unique ID', value: 'businessUniqueID' },
        MinimumDriverCash: { label: 'Minimum Driver Cash', value: 'minimumDriverCash' },
        BaseFare: { label: 'Base Fare ($)', value: 'baseFare' },
        BilledDistance: { label: 'Billed Distance (km)', value: 'billedDistance' },
        ExtraDistance: { label: 'Extra Distance ($)', value: 'perKmCharge' },
        BilledTimeSecond: { label: 'Billed Time (s)', value: 'billedTime' },
        BilledTimePayment: { label: 'Billed Time ($)', value: 'perMinCharge' },
        ExactDistance: { label: 'Exact Distance (km)', value: 'exactDistance' },
        RushHourIncreaseType: { label: 'Rush Hour Increase Type', value: 'rushHourIncreaseType' },
        RushHourIncreaseCoefficient: { label: 'Rush Hour Increase Coefficient', value: 'rushHourIncreaseCoefficient' },
        RushHourIncreaseAmount: { label: 'Rush Hour Increase Amount', value: 'rushHourIncrementAmount' },
        RushHourCharge: { label: 'Rush Hour Charge', value: 'rushHourCharge' },
        DeliveryFee: { label: 'Delivery Fee', value: 'deliveryFee' },
        PromoDiscountPrice: { label: 'Promo Discount ($)', value: 'promoDiscount' },
        PromoDiscountProcentage: { label: 'Promo Discount (%)', value: 'promoPercentage' },
        PromoDiscount: { label: 'Promo Discount (T)', value: 'promoAlias' },
        ReferralDiscount: { label: 'Referral Discount ($)', value: 'referralDiscount' },
        TotalDiscount: { label: 'Total Discount', value: 'totalDiscount' },
        TotalFee: { label: 'Total Fee', value: 'totalFee' },
        DriverShare: { label: 'Driver Share', value: 'driverShare' },
        DriverCommissionFee: { label: 'Driver Commission Fee', value: 'driverExtraCommission' },
        CompanyPay: { label: 'Company Pay', value: 'companyPay' },
        BusinessShare: { label: 'Business Share', value: 'businessShare' },
        CompanyShareMKT: { label: 'Company Share MKT', value: 'companyPaymentBusiness' },
        CompanyShareDelivery: { label: 'Company Share Delivery', value: 'companyPaymentDriver' },
        CompanyShare: { label: 'Company Share', value: 'companyShare' },
        NetIncome: { label: 'Net Income', value: 'netIncome' },
        BusinessDeliveryCollectFee: { label: 'Business Delivery (Collect) Fee', value: 'businessDeliveryFee' },
        ItemFee: { label: 'Item Fee', value: 'itemFee' },
        ProviderFee: { label: 'Provider Fee', value: 'providerFee' },
        RequestedAmount: { label: 'Requested Amount ($)', value: 'totalRequestedAmount' },
        AcceptedAmount: { label: 'Accepted Amount ($)', value: 'totalAcceptedAmount' },
        ServiceFee: { label: 'Service Fee', value: 'serviceFee' },
        OrderAmount: { label: 'Order Amount ($)', value: 'orderAmount' },
        BusinessOrderAmount: { label: 'Business Order Amount ($)', value: 'businessOrderAmount' },
        TotalPaid: { label: 'Total Paid', value: 'totalPaid' },
        CashInflow: { label: 'Cash Inflow', value: 'cashInflow' },
        DeliveryRefund: { label: 'Delivery Refund', value: 'deliveryRefund' },
        OrderRefund: { label: 'Order Refund', value: 'orderRefund' },
        IsPossibleFraud: { label: 'Is Possible Fraud', value: 'isPossibleFraud' },
        HandledWithHack: { label: 'Handled With Hack', value: 'isWithHack' },
        Region: { label: 'Region', value: 'region' },
        SuperRegion: { label: 'Super Region', value: 'superRegion' },
        Billing: { label: 'Billing', value: 'billing' },
        TimestampAccepted: { label: 'Timestamp Accepted', value: 'acceptedTimeStamp' },
        TimestampArrivedAtPickup: { label: 'Timestamp Arrived At Pickup', value: 'arrivedAtPickupTimeStamp' },
        TimestampWayToDelivery: { label: 'Timestamp Way To Delivery', value: 'wayToDeliveryTimeStamp' },
        TimestampArrivedAtDellivery: { label: 'Timestamp Arrived At Dellivery', value: 'arrivedAtDeliveryTimeStamp' },
        TimestampCompleted: { label: 'Timestamp Completed', value: 'completedTimeStamp' },
        ProviderID: { label: 'ProviderID', value: 'providerID' },
        ExternalBookingId: { label: 'External Booking ID', value: 'externalBookingId' },
        Origin: { label: 'Origin', value: 'origin' },
        Responsable: { label: 'Responsable', value: 'responsable' },
        Reassign: { label: 'Reassign', value: 'reassign' },
        Hasissues: { label: 'Has issues', value: 'hasIssues' },
        Issues: { label: 'Issues', value: 'issues' },
        issuesDescriptions: { label: 'Issues Descriptions', value: 'issueDescriptions' },
        CorporateDiscount: { label: 'Corporate Discount', value: 'corporateDiscount' },
        operationBonus: { label: 'Bonus Driver', value: 'operationBonus' },
        TypeProofOfDelivery: { label: 'Type Proof Of Delivery', value: 'typeProofOfDelivery' },
        ProofOfDelivery: { label: 'Proof Of Delivery', value: 'proofOfDelivery' },
        RequiresProofOfDelivery: { label: 'Requires Proof Of Delivery', value: 'requiresProofOfDelivery' },
        BusinessTag: { label: 'Business Tag', value: 'businessTag' },
        EstimatedTimeToPointA: { label: 'Accepted Optimal Time (s)', value: 'estimatedTimeToPointA' },
        EstimatedTimeToPointB: { label: 'Way To Deliver Optimal Time (s)', value: 'estimatedTimeToPointB' },
        isProofValidated: { label: 'Is Proof Of Delivery Validated', value: 'isProofValidated' },
        WasManuallyRequested: { label: 'wasManuallyRequested', value: 'wasManuallyRequested' },
        WasManuallyAssigned: { label: 'wasManuallyAssigned', value: 'wasManuallyAssigned' },
        UsedWhatsapp: { label: 'Used Whatsapp', value: 'usedWhatsapp' },
        UsedMail: { label: 'Used Mail', value: 'usedMail' },
        UsedSMS: { label: 'Used SMS', value: 'usedSMS' },
        LastResponsableReassign: { label: 'Last Responsable Reassign', value: 'lastResponsableReassign' },
        ManualRequestReason: { label: 'Manual Request Reason', value: 'manualRequestReason' },
        ManualAssignReason: { label: 'Manual Assign Reason', value: 'manualAssignReason' },
        WorkspaceName: { label: 'Workspace Name', value: 'workspaceName' },
        Currency: { label: 'Currency', value: 'currency' },
        FromExternalCredentials: { label: 'From External Credentials (Y/N)', value: 'fromExternalCredentials' },
        userBookingCreator: { label: 'User creator booking', value: 'userBookingCreator' },
        WasInBatch: { label: 'Was in Batch', value: 'wasInBatch' },
        TimeOnBatch: { label: 'timeOnBatch', value: 'timeOnBatch' },
        cookTime: { label: 'Cook Time', value: 'cookTime' },
        LastResponsable: { label: 'Last Responsable', value: 'lastResponsable' },
        internalId: { label: 'Internal Id', value: 'internalId' },
        LastResponsableAction: { label: 'Last Responsable Action', value: 'lastResponsableAction' },
        UsedGoogleAddress: { label: 'Used Google Address', value: 'usedGoogleAddress' },
        Tip: { label: 'Tip', value: 'tip' },
    },
    driverSearchReport: {
        driverSearchID: { label: 'DriverSearch Id', value: 'driverSearchID' },
        driverSearchNumericId: { label: 'Search Id', value: 'driverSearchNumericId' },
        bookingNumericId: { label: 'Id del pedido', value: 'bookingNumericId' },
        strategy: { label: 'Strategy', value: 'strategy' },
        round: { label: 'Roound', value: 'round' },
        distance: { label: 'Distance (km)', value: 'distance' },
        searchDate: { label: 'Search Date', value: 'searchDate' },
        searchTime: { label: 'Search Time', value: 'searchTime' },
        wasListed: { label: 'Was Listed', value: 'wasListed' },
        rejectedReason: { label: 'Listed Reason', value: 'rejectedReason' },
        driverName: { label: 'Driver Name', value: 'driverName' },
        driverEmail: { label: 'Driver Email', value: 'driverEmail' },
        wasNotified: { label: 'Was Notified', value: 'wasNotified' },
        notificationDate: { label: 'Notification Date', value: 'notificationDate' },
        notificationTime: { label: 'Notification Time', value: 'notificationTime' },
        status: { label: 'Status', value: 'status' },
        driverLocation: { label: 'Driver Location', value: 'driverLocation' },
        minSearchWallet: { label: 'Min Wallet', value: 'minSearchWallet' },
        driverWallet: { label: 'Driver Wallet', value: 'driverWallet' },
        driverWorkBalance: { label: 'Driver WB', value: 'driverWorkBalance' },
        driverBatteryPercentage: { label: 'Battery', value: 'driverBatteryPercentage' },
        handledBooking: { label: 'Handled Booking (Y/N)', value: 'handledBooking' },
        receivedNotification: { label: 'Received Notification (Y/N)', value: 'receivedNotification' },
        receivedNotificationDate: { label: 'Received Notification Date', value: 'receivedNotificationDate' },
        receivedNotificationTime: { label: 'Received Notification Time', value: 'receivedNotificationTime' },
        rejectedNotification: { label: 'Rejected Notification (Y/N)', value: 'rejectedNotification' },
    },
    businessDeliveryProvidersReport: {
        businessCompanyName: { label: 'Company Name', value: 'businessCompanyName' },
        businessId: { label: 'Business Id', value: 'businessId' },
        deliveryProviders: { label: 'Delivery Providers', value: 'deliveryProviders' },
    },
    totalDriverConnections: {
        driverName: { label: 'Driver Name', value: 'driverName' },
        driverEmail: { label: 'Driver Email', value: 'driverEmail' },
        date: { label: 'Date', value: 'date' },
        time: { label: 'Time', value: 'time' },
        status: { label: 'Status', value: 'status' },
    },
    manualAssignReport: {
        bookingID: { label: 'Booking ID', value: 'bookingID' },
        bookingNumericId: { label: 'Booking Numeric ID', value: 'bookingNumericId' },
        driverID: { label: 'Driver ID', value: 'driverID' },
        driverName: { label: 'Driver Name', value: 'driverName' },
        lastDriverID: { label: 'Last Driver ID', value: 'lastDriverID' },
        lastDriverName: { label: 'Last Driver Name', value: 'lastDriverName' },
        date: { label: 'Date', value: 'dateOfAssignment' },
    },
    dispersionsReport: {
        transactionId: { label: 'Transaction Id', value: 'transactionUniqueID' },
        bookingId: { label: 'Booking Id', value: 'bookingNumericId' },
        date: { label: 'Date', value: (row: any) => moment(row.createdAt).format('yyyy/MM/DD') },
        hour: { label: 'Hour', value: (row: any) => moment(row.createdAt).format('HH:mm:ss') },
        businessName: { label: 'Business Name', value: 'businessName' },
        amount: { label: 'Amount', value: 'amount' },
        comment: { label: 'Comment', value: 'comment' },
        internalId: { label: 'Internal Id', value: 'bookingID' },
        inStore: { label: 'In-Store', value: (row: any) => (row.isCashCollect ? 'N' : 'Y') },
    },
    operationsSummaryReport: {
        bookingNumericId: { label: 'BookingID', value: 'bookingNumericId' },
        typeText: { label: 'Type of Service', value: 'typeText' },
        statusText: { label: 'Booking Status', value: 'statusText' },
        paymentMethod: { label: 'Payment Method', value: 'paymentMethod' },
        month: { label: 'Month', value: 'month' },
        week: { label: 'Week', value: 'week' },
        country: { label: 'Country', value: 'country' },
        bookingStartDate: { label: 'Booking Start Date', value: 'bookingStartDate' },
        day: { label: 'Día', value: 'day' },
        journey: { label: 'Jornada', value: 'journey' },
        pickupCity: { label: 'City', value: 'pickupCity' },
        pickupZone: { label: 'Pickup zone', value: 'pickupZone' },
        customerName: { label: 'Customer', value: 'customerName' },
        driverName: { label: 'Driver Name', value: 'driverName' },
        deliveryProvider: { label: 'Delivery Provider', value: 'deliveryProvider' },
        driverEmail: { label: 'Driver Email', value: 'driverEmail' },
        businessName: { label: 'Business Name', value: 'businessName' },
        workspaceName: { label: 'Workspace Name', value: 'workspaceName' },
        range: { label: 'Range', value: 'range' },
        exactDistance: { label: 'Exact Distance', value: 'exactDistance' },
        billedDistance: { label: 'Billed Distance (km)', value: 'billedDistance' },
        rate: { label: 'Tarifario', value: 'rate' },
        baseFare: { label: 'Base Fare ($)', value: 'baseFare' },
        extraDistance: { label: 'Extra Distance ($)', value: 'extraDistance' },
        deliveryFee: { label: 'Delivery Fee', value: 'deliveryFee' },
        providerFee: { label: 'Provider Fee', value: 'providerFee' },
        ridesProfit: { label: 'Rides Profit', value: 'ridesProfit' },
        apiRequest: { label: 'API request', value: 'apiRequest' },
        tracking: { label: 'Tracking', value: 'tracking' },
        wappCosts: { label: 'Wapp costs', value: 'wappCosts' },
        smsCosts: { label: 'SMS costs', value: 'smsCosts' },
        orderAmount: { label: 'Order Amount ($)', value: 'orderAmount' },
        businessOrderAmount: { label: 'Business Order Amount ($)', value: 'businessOrderAmount' },
        cashCollect: { label: 'Cash collect', value: 'cashCollect' },
        cashCollectCost: { label: 'Cash collect costs', value: 'cashCollectCost' },
        cashCollectProfit: { label: 'Cash collect profit', value: 'cashCollectProfit' },
        onlineFee: { label: 'Online fee', value: 'onlineFee' },
        shield: { label: 'Shield', value: 'shield' },
        partnerShare: { label: 'Partner share', value: 'partnerShare' },
        tip: { label: 'Tip', value: 'tip' },
        revenue: { label: 'Revenue', value: 'revenue' },
        grossProfit: { label: 'Gross Profit', value: 'grossProfit' },
        orderAmountDepurate: { label: 'Order Amount depurado', value: 'orderAmountDepurate' },
        paymentMethodDepurate: { label: 'Payment method depurado', value: 'paymentMethodDepurate' },
        originDepurate: { label: 'Origin depurado', value: 'originDepurate' },
        externalOrderId: { label: 'Provider ID', value: 'externalOrderId' },
        origin: { label: 'Origin', value: 'origin' },
        usedWhatsapp: { label: 'Used Whatsapp', value: 'usedWhatsapp' },
        usedMail: { label: 'Used Mail', value: 'usedMail' },
        usedSms: { label: 'Used SMS', value: 'usedSms' },
        externalCred: { label: 'External cred.', value: 'externalCred' },
        restartByBusiness: { label: 'Restart By Business', value: 'restartByBusiness' },
        restartByBusinessWithPenalty: {
            label: 'Restart By Business With Penalty',
            value: 'restartByBusinessWithPenalty',
        },
    },
    inspectorReport: {
        bookingID: { label: 'Booking ID', value: 'bookingID' },
        description: { label: 'Description', value: 'description' },
        registeredBy: { label: 'Registered By', value: 'registeredBy' },
        type: { label: 'Type', value: 'type' },
        createdAtDate: { label: 'Created At Date', value: 'createdAtDate' },
        createdAtTime: { label: 'Created At Time', value: 'createdAtTime' },
    },
    invoiceTransactions: {
        invoiceId: { label: 'Invoice ID', value: 'invoiceId' },
        workspaceName: { label: 'Workspace Name', value: 'workspaceName' },
        workspaceUniqueID: { label: 'Workspace ID', value: 'workspaceUniqueID' },
        workspaceOwnerEmail: { label: 'Workspace Owner Email', value: 'workspaceOwnerEmail' },
        plan: { label: 'Plan', value: (row: any) => (row.planName || 'Sin plan') },
        type: { label: 'Type', value: 'type' },
        status: { label: 'Status', value: 'status' },
        from: { label: 'From', value: (row: any) => (moment(row.from).utcOffset(-5).format('DD/MM/yyyy')) },
        to: { label: 'To', value: (row: any) => (moment(row.to).utcOffset(-5).format('DD/MM/yyyy')) },
        business: { label: 'Total Business', value: (row: any) => (row.business || 0) },
        amount: { label: 'Amount', value: (row: any) => (row.amount.toFixed(2) || 0) },
        currency: { label: 'Currency', value: 'currency' },
        paymentMethod: { label: 'Payment Method', value: 'paymentMethod' },
        frequency: { label: 'Frequency', value: 'frequency' },
        payDate: { label: 'Date', value: (row: any) => (moment(row.payDate).utcOffset(-5).format('DD/MM/yyyy')) },
        expired: { label: 'Expired', value: (row: any) => (moment(row.expiredAt).utcOffset(-5).format('DD/MM/yyyy')) },
    },
    cashDelivery: {
        dispersionID: { label: 'Dispersion ID', value: 'dispersionUniqueID' },
        week: { label: 'Week', value: 'week' },
        workspaceUniqueID: { label: 'Workspace Unique ID', value: 'workspaceUniqueID' },
        workspaceName: { label: 'Workspace Name', value: 'workspaceName' },
        date: { label: 'Creation date', value: (row: any) => (moment(row.createdAt).utcOffset(-5).format('DD/MM/yyyy')) },
        startDate: { label: 'Start Date', value: (row: any) => (moment(row.startDate).utcOffset(-5).format('DD/MM/yyyy')) },
        endDate: { label: 'End Date', value: (row: any) => (moment(row.endDate).utcOffset(-5).format('DD/MM/yyyy')) },
        payedDate: { label: 'Payed Date', value: (row: any) => (row.payedDate ? (moment(row.payedDate).utcOffset(-5).format('DD/MM/yyyy')) : '-') },
        status: { label: 'Status', value: 'status' },
        amount: { label: 'Amount', value: 'amount' },
        currency: { label: 'Currency', value: 'currency' },
        transactions: { label: 'Transactions', value: 'transactions' },
        inStorePaymentDate: { label: 'Transactions', value: 'accountableAmount' },
    },
};

interface ParseDataType {
  rows: any,
  fileName: string,
  selectedRows: string[],
  headersType: string,
  fileType?: FileType,
}

export const parseData = ({
    rows,
    fileName,
    selectedRows,
    headersType,
    fileType = FileType.CSV,
}: ParseDataType) => {
    const headers = selectedRows.map((key: string) => HEADERS[headersType][key]);
    if (fileType === FileType.XLSX) {
        const data = [
            {
                sheet: 'Reports',
                columns: headers,
                content: rows,
            },
        ];
        const settings = {
            fileName,
            extraLength: 3,
            writeOptions: {},
        };
        xlsx(data, settings);
    } else {
        const csv = parse(rows, { fields: headers });
        const encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${fileName}.csv`);
        link.setAttribute('display', 'none');
        document.body.appendChild(link);
        link.click();
    }
};
