import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    notification,
    PageHeader,
    Table,
    Typography,
} from 'antd';
import { IBusiness } from '../../../types/bussiness';
import { PaginatorType } from '../../../types/paginator';
import { getBusinesses } from '../../../api/business';

interface Props {
    invoiceId: string,
    businessIds: string[],
}

const { Title, Text } = Typography;

const Businesses = ({ invoiceId, businessIds }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [businesses, setBusinesses] = useState<IBusiness[]>([]);
    const [paginator, setPaginator] = useState<PaginatorType>({
        limit: 10,
        skip: 1,
    });

    const columns: any[] = [
        {
            title: 'Unique ID',
            dataIndex: 'userUniqueID',
            key: 'userUniqueID',
            align: 'center' as 'center',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {moment(item.createdAt).format('ddd DD MMM')}
                </Text>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {item.businessID.companyName || '-'}
                </Text>
            ),
        },
        {
            title: 'Contact Email',
            dataIndex: 'email',
            key: 'email',
            align: 'center' as 'center',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            align: 'center' as 'center',
            render: (value: string, item: IBusiness) => (
                <Text>
                    {item.businessID.companyFullMobile || '-'}
                </Text>
            ),
        },
    ];

    const fetchBusinesses = () => {
        setLoading(true);

        const startIndex = (paginator.skip!! - 1) * paginator.limit;
        const endIndex = startIndex + paginator.limit;
        const currentIds = businessIds.slice(startIndex, endIndex);

        getBusinesses({ ...paginator, ids: currentIds })
            .then((response: any) => {
                setBusinesses(response.data.data);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || 'Error fetching businesses';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchBusinesses();
    }, [invoiceId, paginator.skip, paginator.limit]);

    const handleTableChange = (pagination: any) => {
        setPaginator((prev) => ({
            ...prev,
            skip: pagination.current,
        }));
    };

    return (
        <div>
            <PageHeader
                title={(
                    <Title level={5}>
                        Businesses associated with this invoice
                    </Title>
                )}
            />
            <Table
                columns={columns}
                dataSource={businesses}
                loading={loading}
                rowKey="_id"
                style={{ marginLeft: 20 }}
                pagination={{
                    current: paginator.skip,
                    pageSize: paginator.limit,
                    total: businessIds.length,
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default Businesses;
