import cs from 'classnames';
import moment from 'moment';
import { Row, Col, Typography } from 'antd';
import { useContext } from 'react';
import CardDataBase from '../../CardDataBase';
import { DispersionContext } from '../context/dispersionContext';

const { Text } = Typography;

const DispersionData = () => {
    const { dispersion } = useContext(DispersionContext);
    const {
        currency, workspaceName, workspaceUniqueID, payDate, amount,
        week, status, payedDate, startDate, endDate, accountableAmount,
        linkToPayAmount,
    } = dispersion;

    const formatWeek = (week: string) => {
        const weekArray = week?.split('-');
        const weekNumber = weekArray[1];
        const year = weekArray[0];
        return `Week ${weekNumber} of ${year}`;
    };

    const CARD_CONTENT_A = [
        {
            title: 'Workspace Name',
            content: workspaceName,
        },
        {
            title: 'Workspace Unique ID',
            content: workspaceUniqueID,
        },
        {
            title: 'Currency',
            content: currency,
        },
        {
            title: 'Week',
            content: (
                <Text>
                    {week && formatWeek(week)}
                </Text>
            ),
        },
        {
            title: 'Status',
            content: (
                <Text className={cs(status, 'status')}>
                    {status}
                </Text>
            ),
        },
    ];

    const CARD_CONTENT_B = [
        {
            title: 'Start Date',
            content: moment(startDate).format('dddd, D MMM'),
        },
        {
            title: 'End Date',
            content: moment(endDate).format('dddd, D MMM'),
        },
        {
            title: 'Pay Date',
            content: moment(payDate).format('dddd, D MMM'),
        },
        {
            title: 'Payed Date',
            content: (
                <Text>
                    {payedDate ? moment(payedDate).format('dddd, D MMM') : '-'}
                </Text>
            ),
        },
        {
            title: 'Cash collect Amount',
            content: (
                <Text>
                    {`${(amount || 0).toFixed(2)} ${currency}`}
                </Text>
            ),
        },
        {
            title: 'In-store payment amount',
            content: (
                <Text>
                    {`${(accountableAmount || 0).toFixed(2)} ${currency}`}
                </Text>
            ),
        },
        {
            title: 'L2P payment amount',
            content: (
                <Text>
                    {`${(linkToPayAmount || 0).toFixed(2)} ${currency}`}
                </Text>
            ),
        },
    ];

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} xl={12}>
                <CardDataBase title="Dispersion Data" style={{ minHeight: 250 }}>
                    {
                        CARD_CONTENT_A.map((data) => (
                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                                <Col>
                                    <Text strong>
                                        {data.title}
                                        :
                                    </Text>
                                </Col>
                                <Col>{data.content}</Col>
                            </Row>
                        ))
                    }
                </CardDataBase>
            </Col>
            <Col xs={24} xl={12}>
                <CardDataBase title="Payment Dates" style={{ minHeight: 250 }}>
                    {
                        CARD_CONTENT_B.map((data) => (
                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                                <Col>
                                    <Text strong>
                                        {data.title}
                                        :
                                    </Text>
                                </Col>
                                <Col>{data.content}</Col>
                            </Row>
                        ))
                    }
                </CardDataBase>
            </Col>
        </Row>
    );
};

export default DispersionData;
