import { IDispersion } from '../../../interfaces/dispersion.interface';

export const initialContext = {
    dispersions: [] as IDispersion[],
    dispersionsToPay: [] as IDispersion[],
    loading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    selectedRowKeys: [],
    onPageChange: () => { },
    handleParams: () => { },
    handleDownload: () => { },
    fetchDispersions: () => { },
    setSelectedRowKeys: () => { },
    setDispersionsToPay: () => { },
    handlePayMultiple: () => { },
    payMultipleDispersions: () => { },
};

export enum EKeyParams {
    dispersionUniqueID = 'dispersionUniqueID',
    status = 'status',
    startDate = 'startDate',
    endDate = 'endDate',
    week = 'week',
    search = 'search',
    currency = 'currency',
}
