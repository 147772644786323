import moment from 'moment';
import cs from 'classnames';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';
import { IInvoice } from '../context/invoices.interface';
import Actions from './Actions';
import { InvoicesContext } from '../context/invoices.context';
import { InvoicesStatus } from '../../../const/invoices';
import { ECurrency } from '../../../types/payment';

export interface ColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'center' | 'left' | 'right';
    render?: (value: any, record: any) => React.ReactNode;
}

const { Text } = Typography;

const InitialColumns: () => ColumnProps[] = () => {
    const { setItem, fetchData, selectedColumns } = useContext(InvoicesContext);

    const getUTCOffset = (currency: string) => {
        switch (currency) {
        case ECurrency.USD:
            return -5;
        case ECurrency.MXN:
            return -7;
        case ECurrency.DOP:
            return -4;
        case ECurrency.PEN:
            return -5;
        case ECurrency.COP:
            return -5;
        case ECurrency.GTQ:
            return -6;
        case ECurrency.CRC:
            return -6;
        default:
            return -5;
        }
    };

    const getFormatDate = (date: string) => moment(date).format('dddd, D MMM');
    const getFormatTo = (date: string, currency: ECurrency) => moment(date).utcOffset(getUTCOffset(currency)).format('dddd, D MMM');

    const OPTIONAL_COLUMNS = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text>
                    {text || '-'}
                </Text>
            ),
        },
        {
            title: 'Total Business',
            dataIndex: 'business',
            key: 'business',
            align: 'center' as 'center',
            render: (text: number, item: IInvoice) => (
                <Text>{item.type === 'MRR' || item.type === 'MRR_PARTIAL' ? text || 0 : '-'}</Text>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (text: string) => (getFormatDate(text) || '-'),
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (text: string, item: IInvoice) => (getFormatTo(text, item.currency as ECurrency) || '-'),
        },
        {
            title: 'Pay Date',
            dataIndex: 'payDate',
            key: 'payDate',
            render: (text: string) => (getFormatDate(text) || '-'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            align: 'center' as 'center',
            render: (text: number) => (
                <Text>
                    {text.toFixed(2)}
                </Text>
            ),
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            ellipsis: true,
            align: 'center' as 'center',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: true,
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Payment Gateway',
            dataIndex: 'gateway',
            key: 'gateway',
            ellipsis: true,
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            ellipsis: true,
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Expired Date',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            ellipsis: true,
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    text ? (
                        <Space size={4}>
                            <Text>
                                {date}
                            </Text>
                            <Text type="secondary"> at </Text>
                            <Text>
                                {time}
                            </Text>
                        </Space>
                    ) : (
                        <Text>
                            -
                        </Text>
                    )
                );
            },
        },

    ];

    const INITIAL_COLUMNS = [
        {
            title: 'Invoice ID',
            dataIndex: 'invoiceId',
            key: 'invoiceId',
            fixed: 'left',
            ellipsis: true,
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => (getFormatDate(text) || '-'),
        },
        {
            title: 'Workspace',
            dataIndex: 'workspaceName',
            key: 'workspaceID',
            with: 320,
            ellipsis: true,
            render: (text: string, invoice: IInvoice) => (
                <Link to={`/v2/workspaces/${invoice.workspaceID}`}>
                    <Tooltip title={`${text} (${invoice.workspaceUniqueID})`}>
                        <Text
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {text.length > 20 ? `${text.slice(0, 20)}...` : text}
                        </Text>
                    </Tooltip>
                </Link>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            align: 'center' as 'center',
            render: (text: string) => (
                <Text
                    className={cs(text.toLowerCase(), 'status')}
                    style={{ fontSize: 12 }}
                >
                    {InvoicesStatus[text as keyof typeof InvoicesStatus] || text}
                </Text>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            fixed: 'right',
            align: 'center' as 'center',
            width: 100,
            render: (_id: string, record: IInvoice) => (
                <Actions
                    item={record}
                    setItem={setItem}
                    fetchData={fetchData}
                />
            ),
        },
    ];

    const COLUMNS = useMemo(() => {
        const columnOrder = [
            'invoiceId', 'createdAt', 'workspaceID', 'from', 'to', 'payDate', 'amount', 'currency', 'type',
            'business', 'status', 'frequency', 'expiredAt', 'gateway', 'paymentMethod', 'actions',
        ];
        const columns = [
            ...INITIAL_COLUMNS,
            ...OPTIONAL_COLUMNS.filter((column) => selectedColumns.includes(column.key)),
        ];
        const columnMap = Object.fromEntries(columns.map((column) => [column.key, column]));
        return columnOrder.map((key) => columnMap[key]).filter(Boolean);
    }, [selectedColumns]);

    return COLUMNS as ColumnProps[];
};

export default InitialColumns;
