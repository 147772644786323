/* eslint-disable prefer-destructuring */
import { notification } from 'antd';
import React, { createContext, FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getDispersions, payMultiple } from '../../../api/dispersions';
import { IDispersion } from '../../../interfaces/dispersion.interface';
import { ICashDeliveryContext } from './cashDelivery.interface';
import { EKeyParams, initialContext } from './cashDelivery.initial';
import { HEADERS, parseData } from '../../../utils/userReport';
import { FileType } from '../../../types/reports';

export const CashDeliveryContext = createContext<ICashDeliveryContext>(initialContext);

export const CashDeliveryProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [dispersions, setDispersions] = useState<IDispersion[]>(initialContext.dispersions);
    const [dispersionsToPay, setDispersionsToPay] = useState<IDispersion[]>(initialContext.dispersions);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const dispersionUniqueID = searchParams.get('dispersionUniqueID') || '';
    const search = searchParams.get('search') || '';
    const status = JSON.parse(searchParams.get('status') || '[]');
    const week = searchParams.get('week') || '';
    const currency = JSON.parse(searchParams.get('currency') || '[]');

    const getParams = () => {
        const params: any = {};
        dispersionUniqueID && (params.dispersionUniqueID = dispersionUniqueID);
        search && (params.search = search);
        (status && status.length) && (params.status = status);
        (currency && currency.length) && (params.currency = currency);
        week && (params.week = week);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleParams = (key: EKeyParams, value: string | string[]) => {
        if (!value || !value.length) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value instanceof Array ? JSON.stringify(value) : value);
        }

        setSearchParams(searchParams);
    };

    const fetchDispersions = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        getDispersions(params)
            .then((response: any) => {
                const { data: { data } } = response;
                setDispersions(data.data);
                setTotal(data.total);
            })
            .catch((error: any) => {
                console.log({ error });
            })
            .finally(() => setLoading(false));
    };

    const handlePayMultiple = () => {
        const dispersionsToPay = dispersions.filter((dispersion) => selectedRowKeys.includes(dispersion._id));
        setDispersionsToPay(dispersionsToPay);
    };

    const handleDownload = () => {
        const rows = dispersions?.filter((row) => selectedRowKeys.includes(row._id));
        parseData({
            rows,
            fileName: 'cash-delivery',
            selectedRows: Object.keys(HEADERS['cashDelivery' as string]),
            headersType: 'cashDelivery',
            fileType: FileType.XLSX,
        });
    };

    const payMultipleDispersions = () => {
        payMultiple(dispersionsToPay)
            .then(() => notification.success({ message: 'Dispersions paid successfully' }))
            .catch((error) => {
                const description = error.response?.data?.message || 'An error occurred';
                console.log({ error });
                notification.error({ message: 'Error', description });
            })
            .finally(() => {
                setDispersionsToPay([]);
                fetchDispersions();
            });
    };

    return (
        <CashDeliveryContext.Provider
            value={{
                dispersions,
                dispersionsToPay,
                loading,
                total,
                searchParams,
                paginator,
                selectedRowKeys,
                onPageChange,
                handleParams,
                handleDownload,
                fetchDispersions,
                setSelectedRowKeys,
                setDispersionsToPay,
                handlePayMultiple,
                payMultipleDispersions,
            }}
        >
            {children}
        </CashDeliveryContext.Provider>
    );
};
