import moment from 'moment';
import cs from 'classnames';
import { useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    Input,
    Modal,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
    notification,
} from 'antd';
import { BiTrash } from 'react-icons/bi';
import { FaCommentAlt } from 'react-icons/fa';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import {
    deleteTransaction,
    getTransactionConcepts,
    getTransactions,
} from '../../../api/payments';
import { AddTransactionModal } from './AddTransactionModal';
import { OCTOPUS_BASE_URL } from '../../../api';
import { IInvoiceTransaction } from '../../Invoices/context/invoices.interface';

const { Text } = Typography;

const Concept = ({ transaction }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [concept, setConcept] = useState<any>();
    useEffect(() => {
        setLoading(true);
        getTransactionConcepts(transaction._id)
            .then(({ data }: any) => {
                setConcept(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, [transaction]);

    const columns = [
        {
            title: 'Concept',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => amount.toFixed(2),
        },
    ];
    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={concept?.concepts}
            rowKey="_id"
            size="small"
            footer={() => `Total: ${(concept?.total || 0).toFixed(2)}`}
            pagination={false}
        />
    );
};

const PaymentTransactions = ({ payment, onClose }: any) => {
    const [loading, seLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [newTransaction, setNewTransaction] = useState<boolean>(false);

    const allTransactions = useRef<IInvoiceTransaction[]>([]);

    useEffect(() => {
        if (!payment) return;
        seLoading(true);
        getTransactions(payment._id)
            .then(({ data }: any) => {
                allTransactions.current = data.data;
                setTransactions(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => seLoading(false));
    }, [payment]);

    const isOpen = payment;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            width: 100,
            align: 'center' as 'center',
            ellipsis: true,
        },
        {
            title: 'Business',
            dataIndex: 'businessName',
            key: 'businessName',
            align: 'center' as 'center',
        },
        {
            title: 'Manual',
            dataIndex: 'isManual',
            key: 'isManual',
            align: 'center' as 'center',
            width: 100,
            render: (isManual: boolean) => (
                isManual ? (
                    <IoCheckmarkCircleSharp
                        style={{
                            margin: 'auto',
                            width: '100%',
                        }}
                        size={20}
                        color="#08d108"
                    />
                ) : (
                    <IoCloseCircleSharp
                        style={{
                            margin: 'auto',
                            width: '100%',
                        }}
                        size={20}
                        color="#ff0000"
                    />
                )
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center',
        },
        {
            title: 'Booking',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            align: 'center' as 'center',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center' as 'center',
            render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            align: 'center' as 'center',
        },
        {
            title: 'Order Amount',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            align: 'center' as 'center',
            width: 120,
            render: (orderAmount: number) => orderAmount?.toFixed(2) || 0.00,
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 120,
            align: 'center' as 'center',
        },
        {
            title: 'Transaction amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 120,
            align: 'center' as 'center',
            render: (amount: number) => (
                <Text
                    strong
                    type={amount < 0 ? 'danger' : 'success'}
                >
                    {amount.toFixed(2)}
                </Text>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 100,
            align: 'center' as 'center',
            render: (comment: string) => comment && (
                <Tooltip title={comment}>
                    <FaCommentAlt />
                </Tooltip>
            ),
        },
        {
            dataIndex: '_id',
            key: 'actions',
            render: (transactionId: any, transaction: IInvoiceTransaction) => (
                <Button
                    type="text"
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: `Do you want to delete this transaction? ${transaction.transactionId}`,
                            onOk: async () => {
                                try {
                                    const transaction = await deleteTransaction(transactionId);
                                    if (transaction) {
                                        setTransactions((transactions) => transactions.filter((t) => t._id !== transactionId));
                                        notification.success({
                                            message: 'Transaction deleted',
                                        });
                                    }
                                } catch (error) {
                                    notification.error({
                                        message: 'Error deleting transaction',
                                    });
                                }
                            },
                        });
                    }}
                >
                    <BiTrash />
                </Button>
            ),
            width: 60,
        },
    ];

    const handleNewTransaction = () => {
        setNewTransaction(true);
    };

    const handleCancelNewTransaction = (transaction: any) => {
        if (transaction) {
            setTransactions((transactions: any) => [...transactions, transaction]);
        }
        setNewTransaction(false);
    };

    const handleDownload = () => {
        const token = localStorage.getItem('token');
        const invoiceID = payment?._id;
        const utc = (moment().utcOffset() / 60).toString();
        window.open(
            `${OCTOPUS_BASE_URL}/reports?CSVtype=invoiceTransactions&invoiceId=${invoiceID}&workspaceName=${payment?.workspaceName}&token=${token}&utc=${utc}`,
            '_blank',
        );
    };

    const handleClose = () => {
        const amount = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
        const newPayment = {
            ...payment,
            amount,
        };
        onClose(newPayment);
    };

    const handleSearch = (value: string) => {
        if (!value) {
            return setTransactions(allTransactions.current);
        }
        const filtered = allTransactions.current.filter(
            (transaction) => transaction.bookingNumericId.toString().includes(value) || transaction.transactionId.toString().includes(value),
        );
        setTransactions(filtered);
    };

    return (
        <Modal
            title={(
                <Space size={12}>
                    {`Transactions for invoice: ${payment?.invoiceId || ''}`}
                    <Text
                        className={cs(payment?.status.toLowerCase(), 'status')}
                        style={{ fontSize: 12 }}
                    >
                        {payment?.status}
                    </Text>
                </Space>
            )}
            open={isOpen}
            width="100%"
            onOk={handleClose}
            onCancel={handleClose}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            {
                newTransaction && (
                    <AddTransactionModal
                        visible={newTransaction}
                        dispersion={payment}
                        allTransactions={allTransactions}
                        onCancel={(transaction: any) => handleCancelNewTransaction(transaction)}
                    />
                )
            }
            <Row justify="end" gutter={[10, 20]}>
                <Col>
                    <Input.Search placeholder="Booking, Transaction" onSearch={handleSearch} />
                </Col>
                <Col>
                    <Button onClick={handleDownload}>
                        Download
                    </Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleNewTransaction}>
                        Add transaction
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={transactions}
                        bordered
                        scroll={{ x: 1000 }}
                        rowKey="_id"
                        size="small"
                        expandable={{
                            rowExpandable: (record) => !!record.bookingNumericId,
                            expandedRowRender: (record) => <Concept transaction={record} />,
                        }}
                        summary={(pageData) => {
                            let total = 0;
                            let totalOrderAmount = 0;

                            pageData.forEach(({ amount, orderAmount }: any) => {
                                total += amount;
                                totalOrderAmount += orderAmount || 0;
                            });

                            return (
                                <Table.Summary fixed>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={1} />
                                        <Table.Summary.Cell index={2} align="center">
                                            Total
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={3} />
                                        <Table.Summary.Cell index={4} />
                                        <Table.Summary.Cell index={5} />
                                        <Table.Summary.Cell index={6} />
                                        <Table.Summary.Cell index={7} />
                                        <Table.Summary.Cell index={8} />
                                        <Table.Summary.Cell index={9} align="center">
                                            <Text
                                                strong
                                                type={totalOrderAmount < 0 ? 'danger' : 'success'}
                                            >
                                                {totalOrderAmount.toFixed(2)}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={10} />
                                        <Table.Summary.Cell index={11} align="center">
                                            <Text
                                                strong
                                                type={total < 0 ? 'danger' : 'success'}
                                            >
                                                {total.toFixed(2)}
                                            </Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={12} />
                                    </Table.Summary.Row>
                                </Table.Summary>
                            );
                        }}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default PaymentTransactions;
