/* eslint-disable no-unused-vars */
import moment from 'moment';
import { WeekPickerProps } from 'antd/lib/date-picker';
import {
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Button,
    Space,
} from 'antd';
import { EKeyParams } from '../context/cashDelivery.initial';

interface Props {
    searchParams: any;
    hasSelected?: boolean;
    handlePayMultiple: () => void;
    handleDownload: () => void;
    handleParams: (key: EKeyParams, value: string | string[]) => void;
}

const { Search } = Input;
const { WeekPicker }: any = DatePicker;

const Filters = ({
    searchParams,
    hasSelected,
    handlePayMultiple,
    handleDownload,
    handleParams,
}: Props) => {
    const status = JSON.parse(searchParams?.get('status') || '[]');
    const dispersionUniqueID = searchParams?.get('dispersionUniqueID') || '';
    const week = searchParams?.get('week') || '';
    const search = searchParams?.get('search') || '';
    const currency = JSON.parse(searchParams?.get('currency') || '[]');

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'PAID', value: 'PAID' },
        { label: 'ACTIVE', value: 'ACTIVE' },
    ];

    const OPTIONS_CURRENCY = [
        { label: 'USD', value: 'USD' },
        { label: 'MXN', value: 'MXN' },
        { label: 'PEN', value: 'PEN' },
        { label: 'DOP', value: 'DOP' },
        { label: 'COP', value: 'COP' },
    ];

    const onChange: WeekPickerProps['onChange'] = (date, dateString) => {
        const week = dateString.split('th')[0];
        handleParams(EKeyParams.week, week);
    };

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={dispersionUniqueID}
                    placeholder="Dispersion ID"
                    onSearch={(value: any) => handleParams(EKeyParams.dispersionUniqueID, value)}
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={search}
                    onSearch={(value: any) => handleParams(EKeyParams.search, value)}
                    placeholder="Workspace Name"
                />
            </Col>
            <Col>
                <WeekPicker
                    style={{ width: 200 }}
                    onChange={onChange}
                    allowClear
                    placeholder="Week"
                    defaultValue={week && moment(week, 'YYYY-W')}
                />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '200px' }}
                    placeholder="Dispersion status"
                    onChange={(value: any) => handleParams(EKeyParams.status, value)}
                    options={OPTIONS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={currency}
                    mode="multiple"
                    style={{ width: '150px' }}
                    placeholder="Currency"
                    onChange={(value: any) => handleParams(EKeyParams.currency, value)}
                    options={OPTIONS_CURRENCY}
                    maxTagCount="responsive"
                />
            </Col>
            {
                hasSelected && (
                    <Space size={0}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handleDownload}
                            >
                                Download
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handlePayMultiple}
                            >
                                Pay Multiple
                            </Button>
                        </Col>
                    </Space>
                )
            }
        </Row>
    );
};

export default Filters;
