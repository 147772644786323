import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
    Dropdown,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { FaCommentAlt } from 'react-icons/fa';
import { IoMdOpen } from 'react-icons/io';

import TransactionMenu from './Menu';
import { ITransaction } from '../../../interfaces/transaction.interface';

const { Text } = Typography;

export const InitialColumns = () => {
    const navigate = useNavigate();

    const INITIAL_COLUMNS = [
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionUniqueID',
            key: 'transactionUniqueID',
        },
        {
            title: 'Booking ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            render: (text: string, transaction: ITransaction) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => {
                        if (!transaction) return;
                        navigate(`/v2/booking?id=${transaction.bookingID}`);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {`# ${text}` || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            render: (text: string, transaction: ITransaction) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => {
                        if (!transaction) return;
                        navigate(`/v2/business/${transaction.businessID}`);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {text || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: number, record: ITransaction) => {
                const { currency } = record;
                return (
                    <Text>
                        {`${text.toFixed(2)} ${currency}`}
                    </Text>
                );
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center',
            width: 140,
            render: (type: string, item: ITransaction) => (
                <Text>
                    {
                        type === 'L2P'
                            ? 'L2P'
                            : item.isCashCollect
                                ? 'CASH'
                                : 'IN-STORE'
                    }
                </Text>
            ),
            filters: [
                {
                    text: 'L2P',
                    value: 'L2P',
                },
                {
                    text: 'IN-STORE',
                    value: 'IN-STORE',
                },
                {
                    text: 'CASH',
                    value: 'CASH',
                },
            ],
            onFilter: (filterValue: string, record: ITransaction) => {
                if (filterValue === 'L2P') return record.type === 'L2P';
                if (filterValue === 'IN-STORE') return !record.isCashCollect;
                return !record.isCashCollect && record.type !== 'L2P';
            },
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            align: 'center' as 'center',
            width: 120,
            render: (text: string) => text && (
                <Tooltip title={text}>
                    <FaCommentAlt />
                </Tooltip>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 60,
            render: (text: string, record: ITransaction) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <TransactionMenu
                            transaction={record}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} style={{ verticalAlign: 'middle' }} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return INITIAL_COLUMNS;
};
