import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IDispersion } from '../interfaces/dispersion.interface';

export const getDispersions = ({
    limit,
    page,
    workspaceID,
    dispersionUniqueID,
    status,
    search,
    currency,
    week,
}: any) => {
    const params = new URLSearchParams({
        limit: limit.toString(),
        page: page.toString(),
    });

    (status && status.length) && params.append('status', JSON.stringify(status));
    (search && search !== '') && params.append('search', search);
    (workspaceID && workspaceID !== '') && params.append('workspaceID', workspaceID);
    (dispersionUniqueID && dispersionUniqueID !== '') && params.append('dispersionUniqueID', dispersionUniqueID);
    (currency && currency.length) && params.append('currency', JSON.stringify(currency));
    (week) && params.append('week', week.toString());

    const url = `${OCTOPUS_BASE_URL}/dispersions`;
    const response = axios.get(url, { params });
    return response;
};

export const getDispersion = (dispersionID: string) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${dispersionID}`;
    const response = axios.get(url);
    return response;
};

export const getTransactions = (dispersionID: string) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${dispersionID}/transactions`;
    const response = axios.get(url);
    return response;
};

export const deleteTransaction = (payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/transactions`;
    const response = axios.delete(url, { data: payload });
    return response;
};

export const payDispersion = (id: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}/pay`;
    const response = axios.post(url, payload);
    return response;
};

export const addTransaction = (payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/transactions`;
    const response = axios.post(url, payload);
    return response;
};

export const addInvoiceTransaction = (dispersionID: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${dispersionID}/transactions`;
    const response = axios.post(url, payload);
    return response;
};

export const payMultiple = (payload: IDispersion[]) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/pay/multiple`;
    const response = axios.post(url, payload);
    return response;
};

export const updateDispersion = (id: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}`;
    const response = axios.put(url, payload);
    return response;
};

export const deleteDispersion = (id: string, data: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}`;
    const response = axios.delete(url, { data });
    return response;
};
